import { useCookies } from "react-cookie";
import Image from "@/components/Atoms/Image";
import { Button, ButtonMaster } from "@/components/Atoms/Buttons";
import { Paragraph } from "@/components/Atoms/Paragraph";
import { HeroProductTitle } from "@/atoms/Title";

import styles from "./index.module.scss";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { pageRelatedLink } from "@/lib/functions";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

interface Props {
	siteConfig: any;
}

export default function AgeGate({ siteConfig }: Props) {
	const [cookies, setCookie, removeCookie] = useCookies(["age-gate"]);
	const { defaultLocale } = useRouter();

	const yesClick = () => {
		setCookie("age-gate", 1, { path: "/" });
		window.location.reload();
	};

	const noClicked = () => {
		window.location.href = siteConfig.ageGateRedirectUrl;
	};
	const ageGateButton = siteConfig.ageGateButton[0];

	const style = {
		"--img": `url(${siteConfig.ageGateBackgroundImage?.url})`,
		background: siteConfig.ageGateBackgroundColor?.hex,
	} as React.CSSProperties;

	if (siteConfig.logoPicker) {
		var logo = siteConfig.headerMenu.logo;
	} else {
		var logo = siteConfig.headerMenu.logoColour;
	}

	const { t } = useTranslation();
	return (
		<div
			style={{ background: siteConfig.agBackgroundColor?.color?.hex }}
			className={` ${styles.ageGatePage} relative mx-auto  flex flex-col items-center justify-center`}
		>
			<div className="w-full">
				<div className=" mx-auto max-w-[60rem] relative z-10">
					<div className={` lg:pt-12 lg:pb-12  flex justify-center `}>
						<Image className="h-[5rem] lg:h-[8rem] max-w-[15rem]" data={logo} />
					</div>

					<div className="flex flex-col gap-8 px-12 items-center mt-6 md:mt-0 ">
						<div className="flex flex-col items-center w-fit">
							{" "}
							<HeroProductTitle
								tag="h2"
								data={siteConfig.ageGateWelcome}
								color={siteConfig.agTaglineColor?.color}
								layout={"fixedCenter"}
								className={"brandTitleGate"}
							/>
							{siteConfig.addTitleUnderline && (
								<>
									{siteConfig.customUnderlineAsset?.img ? (
										<CustomUnderline
											shape={siteConfig.customUnderlineAsset?.img}
											color={siteConfig.underlineColor?.color}
										/>
									) : (
										<Underline color={siteConfig.underlineColor?.color} />
									)}
								</>
							)}
						</div>

						<Paragraph
							color={siteConfig.agHeadingColor?.color}
							copy={siteConfig.ageGateTitle}
							className={`brandParagraphGateLarger`}
							layout="fixedCenter"
						/>

						<Paragraph
							color={siteConfig.agHeadingColor?.color}
							copy={siteConfig.ageGateContent}
							className={`brandParagraphGate`}
							layout="fixedCenter"
						/>
						<HeroProductTitle
							tag="h4"
							color={siteConfig.agBodyColor?.color}
							data={siteConfig.ageGateQuestion}
							layout={"fixedCenter"}
							className={"SubBrandTitleGate"}
						/>

						<div className="flex gap-2 md:gap-12 items-center w-auto flex-row justify-center  mb-6  md:pb-32">
							<ButtonMaster
								anim={""}
								onClick={yesClick}
								type={siteConfig.ageGateButton[0]?.buttonStyle}
								cta={siteConfig.ageGateButton[0]?.typeColor?.color}
								color={siteConfig.ageGateButton[0]?.labelColor?.color}
								customIcon={siteConfig.ageGateButton[0]?.icon?.img}
								customColor={siteConfig.ageGateButton[0]?.iconColor?.color}
								target={``}
								link={null}
							>
								{t("yes")}
							</ButtonMaster>
							<ButtonMaster
								anim={""}
								onClick={noClicked}
								type={siteConfig.ageGateButton[0]?.buttonStyle}
								cta={siteConfig.ageGateButton[0]?.typeColor?.color}
								color={siteConfig.ageGateButton[0]?.labelColor?.color}
								customIcon={siteConfig.ageGateButton[0]?.icon?.img}
								customColor={siteConfig.ageGateButton[0]?.iconColor?.color}
								target={``}
								link={null}
							>
								{t("no")}
							</ButtonMaster>
						</div>
					</div>
				</div>
				<FullBackgroundImage data={siteConfig.ageGateBackgroundImage?.url} />
			</div>
		</div>
	);
}
