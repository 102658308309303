import AgeGate from '@/components/Templates/Main/AgeGate'
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { pageHandler } from "@/lib/pages-handler";
import { _getSiteConfiguration } from '@/dato-api/site-config-cache';

export default function AgeGatePage({ siteConfig }) {

  return (
    <AgeGate siteConfig={siteConfig} />
  );
}

export async function getStaticProps(context) {
  const siteConfig = await _getSiteConfiguration(context.locale, false).then((result) => result.siteConfiguration);
  const result = []
  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    ""
  );

  return {
    props: {
      siteConfig: siteConfig,
      ...pageData.sst,
      data: { ...pageData.data },
      preview: pageData.preview,
    },
  };
}